// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/ashish/Documents/ashish/workspace/polyglots/site/polyglots/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-js": () => import("/Users/ashish/Documents/ashish/workspace/polyglots/site/polyglots/src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-js": () => import("/Users/ashish/Documents/ashish/workspace/polyglots/site/polyglots/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/Users/ashish/Documents/ashish/workspace/polyglots/site/polyglots/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-consulting-js": () => import("/Users/ashish/Documents/ashish/workspace/polyglots/site/polyglots/src/pages/services/consulting.js" /* webpackChunkName: "component---src-pages-services-consulting-js" */),
  "component---src-pages-services-development-js": () => import("/Users/ashish/Documents/ashish/workspace/polyglots/site/polyglots/src/pages/services/development.js" /* webpackChunkName: "component---src-pages-services-development-js" */),
  "component---src-pages-services-training-js": () => import("/Users/ashish/Documents/ashish/workspace/polyglots/site/polyglots/src/pages/services/training.js" /* webpackChunkName: "component---src-pages-services-training-js" */),
  "component---src-pages-work-js": () => import("/Users/ashish/Documents/ashish/workspace/polyglots/site/polyglots/src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/ashish/Documents/ashish/workspace/polyglots/site/polyglots/.cache/data.json")

